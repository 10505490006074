@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

tr {
  height: 42px;
  margin-bottom: 8px;
}
tbody > tr {
  border-bottom: 1px solid var(--Background-Behind, #000000)
}
tbody > tr:last-child{
  border:none;
}
tbody > tr > td , tbody > tr > td > div > label{
  padding:8px;
  max-width: 80px;    /* Set a fixed width */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;    /* Hide overflowed text */
  text-overflow: ellipsis;
}
thead > tr > th{
  padding:8px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
Button:hover{
  opacity: 0.6;
}
.font-grey {
  color: #CDCDCD;
}

.font-grey1 {
  color: #D9DCFB;
}
.font-faded{
  color:#666666;
}

.Button,
.font-button-700 {
  font-family: "SUSE", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 25.2px;
}

.h1, h1,
.font-h1-700 {
  font-family: "SUSE", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 40.32px;
}

.font-h2-700 {
  font-family: "SUSE", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40.32px;
}

.h3, h3,
.font-h3-700 {
  font-family: "SUSE", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.24px;

}

h4, .h4,
.font-h5-700 {
  font-family: "SUSE", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25.2px;
}

h5, .h5,
.font-h5-700 {
  font-family: "SUSE", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
}

.B5,
.font-b1-400 {
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.font-b2-400 {
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.B3,
.font-b3-400 {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.B4,
.font-b4-500 {
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.b5,
.font-b5-500 {
  font-family: "Quicksand", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 10px;
}

.btn-white {
  background-color: #D9DCFB;
  border-radius: 14px;
  color: #0D102F;
  font-family: "SUSE", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
}

.btn-blue {
  background-color: #8D97F4;
  border-radius: 14px;
  color: #0D102F;
  font-family: "SUSE", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
}

.btn-grey {
  background-color: #292929;
  border-radius: 16px;
  color: #fff;
  height: 32px;
  display: flex;
  column-gap: 16px;
}

.btn-grey1 {
  background-color: #292929;
  border-radius: 16px;
  color: #fff;
  height: 32px;
  width: fit-content;
  padding: 8px 16px;
  display: flex;
  column-gap: 8px;
}

.input-text {
  max-height: 40px;
  height: 100%;
  background-color: #292929;
  border-radius: 20px;
  padding: 8px 16px;
}

.input-select {
  appearance: none;
  /* For most browsers */
  -webkit-appearance: none;
  /* For Safari */
  -moz-appearance: none;
  /* For Firefox */
  background: url('./ic_arrow_down.png') no-repeat right 10px center;
  background-size: 24px 24px;
  padding-right: 30px;
  /* Space for the custom arrow */
  position: relative;
  border: 1px solid #292929;
  background-color: #292929;
  color: #D9DCFB;
}

.img-btn {
  background: none;
  border: none;
  opacity: 1;
}
.active{
  background-color: #292929;
}
.img-btn:hover {
  opacity: 0.7;
}

.letter-f12-700 {
  font-family: "SUSE", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
}

.letter-f16-400 {
  font-family: "SUSE", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
}

.letter-f18-400 {
  font-family: "SUSE", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 12px;
}

.letter-f20-400 {
  font-family: "Effra", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.title-f40-700 {
  font-family: "SUSE", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50.4px;
}

.title-f24-700 {
  font-family: "SUSE", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30.24px;
}

.title-f20-700 {
  font-family: "SUSE", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 25.2px;
}

.title-f12-700 {
  font-family: "SUSE", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 25.2px;
}

.title-f8-700 {
  font-family: "SUSE", sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
}

.error{
  text-align: left;
  color: #fa3a3a;
  font-size: 11px
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000b0;
  /* Semi-transparent white background */
  z-index: 9999;
  /* Ensure it's above other content */
}

::-webkit-scrollbar {
  width: 3px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #00000000; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners of the scrollbar thumb */
  border: 3px solid #f0f0f0; /* Optional: Adds a border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563; /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6b7280 #00000000;
}